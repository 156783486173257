/* eslint-disable max-classes-per-file */
import { observable } from 'mobx';
import { persist, create } from 'mobx-persist';

class Sections {
  @persist('list') @observable value = [];

  @observable clear() {
    this.value = [];
  }
}
class Token {
  @persist @observable value = '';

  @observable clear() {
    this.value = '';
  }
}
class User {
  @persist('object') @observable value = {};

  @observable clear() {
    this.value = {};
  }
}

class Notification {
  @persist @observable notify = 0;

  @observable clear() {
    this.notify = 0;
  }
}

// const store = (window.store = new Store());
const notification = new Notification();
const userStore = new User();
const tokenStore = new Token();
const sectionsStore = new Sections();
const hydrate = create({
  storage: localStorage,
  jsonify: true,
});
hydrate('tulip-user', userStore).then(() => {});
hydrate('tulip-token', tokenStore).then(() => {});
hydrate('tulip-sections', sectionsStore).then(() => {});

export default {
  userStore,
  tokenStore,
  sectionsStore,
  notification,
};
